import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'
import { normalize } from 'normalizr'

import { getDateTime } from '~/src/Lib/Utils'
import { ControlDailyJournal } from '~/src/Store/Schemas'
import { createAppIsReadySelector } from '~/src/Store/utils'

const initialCompletedEventsBundle = createAsyncResourceBundle({
  name: 'facilityIrrigationCompletedEvents',
  actionBaseType: 'FACILITY_IRRIGATION_COMPLETED_EVENTS',
  getPromise: ({ apiFetch, store }) => apiFetch('/controlDailyJournals/', {
    // eslint-disable-next-line babel/camelcase
    event_types: ['EMERGENCY_SHOT', 'IRRIGATION_COMPLETED', 'RUN_ONCE', 'SCHEDULE_DELETED'],
    pagination: 0,
    start: getDateTime('now').minus({ day: 1 }).toISO(),
  }).then(response => {
    const { entities } = normalize(response, [ControlDailyJournal])
    store.doEntitiesReceived(entities)
    return response
  }),
  staleAfter: ms.minutes(5),
})

export default {
  ...initialCompletedEventsBundle,
  selectIrrigationScheduleDeletedByScheduleId: createSelector(
    'selectControlDailyJournals',
    controlDailyJournals => Object.values(controlDailyJournals).reduce((acc, cdj) => {
      if (cdj.eventType !== 'SCHEDULE_DELETED' || !cdj.irrigationScheduleIds?.length) return acc
      const [scheduleId] = cdj.irrigationScheduleIds
      acc[scheduleId] = cdj
      return acc
    }, {}),
  ),
  reactFetchFacilityIrrigationCompletedEvents: createAppIsReadySelector({
    dependencies: [
      'selectFacilityIrrigationCompletedEventsShouldUpdate',
      'selectQueryObject',
      'selectRouteInfo',
    ],
    resultFn: (shouldUpdate, { view }, { pattern }) => {
      if (shouldUpdate && pattern === '/irrigation' && view === 'schedules') {
        return { actionCreator: 'doFetchFacilityIrrigationCompletedEvents' }
      }
      return null
    },
  }),
}
