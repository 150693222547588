import { seconds } from 'milliseconds'
import WebSocketBridge from 'reconnecting-websocket'

import config from '~/src/App/config'
import { getTokens } from '~/src/Lib/Auth'
import { EMPTY_OBJECT } from '~/src/Lib/Utils'
import { getLSM } from '~/src/Store/bundles/me/utils'

import createLogger from '../Lib/Logging'

const logger = createLogger('IO/Socket')

const maxReconnectionDelay = 64e3

const ERROR_CODES = {
  4010: 'NO_USER',
  4011: 'NOT_AUTHENTICATED',
  4020: 'INVALID_FACILITY',
  4030: 'NO_PERMISSION',
}

const urlProvider = async () => {
  const { access = '', user } = getTokens()
  const { facility } = getLSM()

  if (!Number(facility) || access.length < 100) {
    console.warn('[IO/Socket] aborted WS URL due to invalid facility ID or access token or persistent error', {
      user,
      accessTokenLength: access?.length ?? 0,
      facilityID: Number(facility) || facility,
    })
    return 'ws://localhost:3000'
  }
  return `${config.WEBSOCKET_HOST}/ws/websockets/${facility}/${access}`
}

let socket = null
let lastAttempt = 0
/**
 * Connect a websocket to the server and attach message and error handlers
 * @param {(data: any) => void} messageHandler
 * @param {(error: any) => void} errorHandler
 */
export default async (messageHandler, errorHandler) => new Promise(resolve => {
  logger.debug('attempting websocket connect')
  if (
    socket
    && socket.readyState !== WebSocketBridge.CLOSING
    && socket.readyState !== WebSocketBridge.CLOSED
    && typeof socket.close === 'function'
  ) {
    logger.debug('websocket already connected; closing it')
    socket.close()
  }
  if (Date.now() - lastAttempt < seconds(5)) {
    logger.warn('websocket connection attempt too soon after last attempt')
    resolve(null)
    return
  }

  lastAttempt = Date.now()

  try {
    socket = new WebSocketBridge(urlProvider, [], {
      connectionTimeout: 10e3,
      maxReconnectionDelay,
      minReconnectionDelay: 1e3,
      reconnectionDelayGrowthFactor: 2,
    })
    // deepcode ignore InsufficientPostmessageValidation: Snyk is wrong. This isn't a postmessage listener
    socket.addEventListener('message', event => {
      if (!event.data || !event.data.startsWith('{')) {
        logger.debug('invalid event.data')
        return
      }
      try {
        const data = JSON.parse(event.data)
        messageHandler(data)
      } catch (err) {
        logger.debug(err)
      }
    })
    socket.addEventListener('error', ({ eventPhase, type, target = EMPTY_OBJECT }) => {
      const { readyState, url } = target
      const error = { eventPhase, readyState, type, url }
      console.error('RWS error:', error)
      errorHandler(error)
      resolve(null)
    })

    socket.addEventListener('close', event => {
      const { code, reason, wasClean } = event
      if (code < 4000) return
      if (code in ERROR_CODES) {
        const { [code]: type } = ERROR_CODES
        const error = { code, reason, type, wasClean }
        console.error('Websocket closed due to invalid state', error)
        errorHandler(error)
        return
      }
      console.warn('RWS closed:', { code, reason, wasClean })
    })
    socket.addEventListener('open', () => resolve(socket))
  } catch (err) {
    console.warn('websocket error:', err)
  }
})
