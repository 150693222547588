import { createSelector } from 'redux-bundler'

import { seconds } from 'milliseconds'

import config from '~/src/App/config'
import { PACKET_HANDLERS, Queue } from '~/src/IO/Messages'
import connectWebSocket from '~/src/IO/Socket'
import getGlobal from '~/src/Lib/getGlobal'
import createLogger from '~/src/Lib/Logging'
import { defer, EMPTY_OBJECT } from '~/src/Lib/Utils'

import { REACTOR_PRIORITIES } from '../constants'
import { createAppIsReadySelector } from '../utils'

const logger = createLogger('Store/sockets')
const defaultState = {
  connecting: {},
  connections: {},
  lastError: null,
}

let job = null
let received = 0
let handled = 0
let invalid = 0

const CONNECT_SOCKET = 'CONNECT_SOCKET'
const CONNECTING_SOCKET = 'CONNECTING_SOCKET'
const DISCONNECT_SOCKET = 'DISCONNECT_SOCKET'

let queue

const jobHandler = (store, deadline) => {
  const { didTimeout } = deadline
  // If we're running due to a timeout, give ourselves half the time, otherwise
  // give ourselves a 10ms buffer to do housekeeping
  const offset = Math.max(didTimeout ? deadline.timeRemaining() / 2 : 10, 10)
  while (queue.size && ((deadline.timeRemaining() - offset) > 0 || didTimeout)) {
    const work = queue.shift()
    const { [work.action]: handler = PACKET_HANDLERS.DEFAULT } = PACKET_HANDLERS
    handler(work, store, 'ws')
    handled += 1
    if (didTimeout) break
  }
  // If there's still work to do, queue next job
  if (queue.size) {
    job = requestIdleCallback(jobHandler.bind(null, store), { timeout: 1000 })
    return
  }
  // No work left
  // Clear job ID
  job = null
}
const socketMessageHandler = ({ packet, store }) => {
  received += 1

  if (!packet?.action) {
    invalid += 1
    logger.debug('invalid packet shape', packet)
    return
  }
  queue.push(packet)
  if (!job) {
    job = requestIdleCallback(jobHandler.bind(null, store), { timeout: 1000 })
  }
  if (received % 100 === 0) {
    logger.debug(store.selectSocketStats())
  }
}

export default {
  name: 'sockets',
  init: store => {
    const { ENVIRONMENT } = store.selectConfig() ?? {}
    const { location } = getGlobal()
    if (ENVIRONMENT !== 'production' && !location.hostname.includes('app.aroya.io')) {
      const channel = new BroadcastChannel('FAKE_WEBSOCKET')
      const fakeWSHandler = event => {
        socketMessageHandler({
          packet: event.data,
          store,
          dispatch: store.dispatch
        })
      }
      channel.addEventListener('message', fakeWSHandler)
      return () => {
        channel.removeEventListener('message', fakeWSHandler)
        channel.close()
        if (job) {
          cancelIdleCallback(job)
        }
      }
    }
    return () => {
      if (job) {
        cancelIdleCallback(job)
      }
    }
  },
  reducer: (state = defaultState, action = EMPTY_OBJECT) => {
    switch (action.type) {
      case CONNECT_SOCKET: {
        const [facilityId, socket] = action.payload
        const { [facilityId]: _, ...nextConnecting } = state.connecting
        return {
          connecting: nextConnecting,
          connections: {
            ...state.connections,
            [facilityId]: socket,
          },
          lastError: null,
        }
      }
      case CONNECTING_SOCKET:
        return {
          ...state,
          connecting: {
            ...state.connecting,
            [action.payload]: Date.now(),
          }
        }
      case DISCONNECT_SOCKET: {
        const {
          connecting: { [action.payload]: _, ...nextConnecting },
          connections: { [action.payload]: oldSocket, ...nextSockets },
        } = state
        if (oldSocket && oldSocket.readyState < 2 && oldSocket.close) {
          defer(() => {
            try {
              oldSocket.close()
            } finally {
              // do nothing
            }
          }, defer.priorities.lowest)
        }
        const nextState = {
          connecting: nextConnecting,
          connections: nextSockets,
        }
        if (action.error) {
          nextState.lastError = action.error
        }
        return nextState
      }
      default:
        return state && 'connecting' in state ? state : { ...state, ...defaultState }
    }
  },
  selectSocketsRoot: state => state.sockets,
  selectSockets: createSelector(
    'selectSocketsRoot',
    sockets => sockets.connections
  ),
  selectSocketStats: () => [
    'WS Message Handler Status:',
    `received packets: ${received}`,
    `invalid packets:  ${invalid}`,
    `handled packets:  ${handled}`,
    `queued packets: ${queue?.size ?? 0}`,
  ].join('\n'),
  reactConnectSocket: createAppIsReadySelector({
    dependencies: [
      'selectFullMe',
      'selectSocketsRoot',
      'selectCurrentFacilityId'
    ],
    resultFn: (me, sockets, currentFacilityId) => {
      if (!config.ENABLE_WEBSOCKET && config.ENABLE_EVENTSTREAM) {
        return null
      }

      if (currentFacilityId in sockets.connections) {
        return null
      }

      if (currentFacilityId in sockets.connecting) {
        const { [currentFacilityId]: connectingTS } = sockets.connecting
        const connectingMS = Date.now() - connectingTS
        if (connectingMS < seconds(10)) {
          logger.debug(
            'already trying to connect to this membership, waiting until',
            new Date(connectingTS + seconds(10)).toISOString(),
            'to try again'
          )
          return null
        }
      }

      queue = new Queue()
      return {
        actionCreator: 'doConnectSocket',
        args: [currentFacilityId],
        priority: REACTOR_PRIORITIES.HIGH,
      }
    }
  }),
  doDisconnectSocket: (facility, error) => ({ dispatch }) => {
    dispatch({
      type: DISCONNECT_SOCKET,
      payload: facility,
      error,
    })
  },
  doConnectSocket: currentFacilityId => async ({ store, dispatch }) => {
    dispatch({
      type: CONNECTING_SOCKET,
      payload: currentFacilityId,
    })
    const sockets = store.selectSockets()
    Object.entries(sockets)
      .filter(([facId, socket]) => Number(facId) !== currentFacilityId || socket.readyState > 1)
      .forEach(([facId, socket]) => {
        if (socket && typeof socket.close === 'function') {
          socket.close(1000, 'Switched facilities')
        }
        store.doDisconnectSocket(facId)
      })
    if (!currentFacilityId) {
      logger.warn('doConnectSocket unable to connect due to missing or invalid membership:', currentFacilityId)
    }
    const socket = await connectWebSocket(
      packet => socketMessageHandler({ packet, store, dispatch }),
      error => store.doDisconnectSocket(currentFacilityId, error)
    )
    if (!socket) return
    dispatch({
      type: CONNECT_SOCKET,
      payload: [currentFacilityId, socket],
    })
  },
}
