import { createSelector } from 'redux-bundler'

import { Duration } from 'luxon'

import createListBundle, { defaultInitialState } from '~/src/Lib/createListBundle'
import { getDateTime } from '~/src/Lib/Utils'
import { IrrigationManualEvent as schema } from '~/src/Store/Schemas'

const irrigationManualEventListBundle = createListBundle({
  entityName: 'irrigationManualEvent',
  initialState: { ...defaultInitialState, room: null },
  schema,
  fetchHandler: ({ apiFetch, params: { room } }) => {
    if (!room) return []
    return apiFetch('/irrigationManualEvents/', { pagination: 0, room })
  },
})

export default {
  ...irrigationManualEventListBundle,
  selectIrrigationManualEventMeta: createSelector(
    'selectCurrentIrrigationManualEventList',
    eventList => eventList.map(data => {
      const actualEndDT = getDateTime(data.actualEnd)
      const actualStartDT = getDateTime(data.actualStart)
      const expectedEndDT = getDateTime(data.expectedEnd)
      const expectedStartDT = getDateTime(data.expectedStart)
      const startDelay = data.actualStart ? (
        actualStartDT.diff(expectedStartDT).rescale().toHuman({ unitDisplay: 'short' })
      ) : null
      const expectedDuration = expectedEndDT.diff(expectedStartDT).rescale().toHuman({ unitDisplay: 'short' })
      const actualDuration = data.actualEnd ? (
        actualEndDT.diff(actualStartDT).rescale().toHuman({ unitDisplay: 'short' })
      ) : null
      const humanizedDuration = Duration.fromISOTime(data.duration).rescale().toHuman({ unitDisplay: 'short' })
      return {
        data,
        startDelay,
        requestedDuration: `${humanizedDuration} \u00d7 ${data.portIds.length} ports`,
        expectedDuration,
        actualDuration,
      }
    }),
  ),
  reactIrrigationManualEventListSetRoom: createSelector(
    'selectAvailableFeatures',
    'selectCurrentRoomId',
    'selectIrrigationManualEventListApiParams',
    'selectPermittedActions',
    (availableFeatures, roomId, apiParams, permittedActions) => {
      const allowed = availableFeatures.has('MANUAL_IRRIGATION')
        && permittedActions.has('view_irrigation')
      if (allowed && roomId && apiParams.room !== roomId) {
        return { actionCreator: 'doIrrigationManualEventListSetParams', args: [{ room: roomId }] }
      }
      return null
    },
  ),
}
