import { createSelector } from 'redux-bundler'

export default {
  name: 'badges',
  selectNavBadgesByUrl: createSelector(
    () => {
      const badges = {
        '/compliance': {
          color: 'error',
          value: '!',
        },
      }
      return badges
    },
  ),
}
