import i18n from 'i18n-literally'
import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'
import { normalize } from 'normalizr'

import { IrrigationSchedule } from '~/src/Store/Schemas'
import { createAppIsReadySelector } from '~/src/Store/utils'

const initialDeletedSchedulesBundle = createAsyncResourceBundle({
  name: 'facilityIrrigationDeletedSchedules',
  actionBaseType: 'FACILITY_IRRIGATION_DELETED_SCHEDULES',
  getPromise: ({ apiFetch, store }) => apiFetch('/irrigationSchedules/recently_deleted/').then(response => {
    const { entities } = normalize(response.map(({ name, ...schedule }) => ({
      ...schedule,
      name: i18n`${name.split(/__DELETED.*$/)[0]} (deleted)`,
    }), {}), [IrrigationSchedule])
    store.doEntitiesReceived(entities)
    return response
  }),
  staleAfter: ms.minutes(1),
})

export default {
  ...initialDeletedSchedulesBundle,
  selectFacilityIrrigationDeletedSchedulesByProgramId: createSelector(
    'selectFacilityIrrigationDeletedSchedules',
    deletedSchedules => {
      if (!deletedSchedules) return {}
      return deletedSchedules.reduce((asbp, schedule) => {
        schedule.programs.forEach(({ id }) => {
          asbp[id] = schedule
        })
        return asbp
      }, {})
    },
  ),
  reactFetchFacilityIrrigationDeletedSchedules: createAppIsReadySelector({
    dependencies: [
      'selectFacilityIrrigationDeletedSchedulesShouldUpdate',
      'selectQueryObject',
      'selectRouteInfo',
    ],
    resultFn: (shouldUpdate, { view }, { pattern }) => {
      if (shouldUpdate && pattern === '/irrigation' && view === 'schedules') {
        return { actionCreator: 'doFetchFacilityIrrigationDeletedSchedules' }
      }
      return null
    },
  }),
}
