import { createSelector } from 'redux-bundler'

import { seconds } from 'milliseconds'

import config from '~/src/App/config'
import { PACKET_HANDLERS, Queue } from '~/src/IO/Messages'
import connect from '~/src/IO/SSE'
import getGlobal from '~/src/Lib/getGlobal'
import createLogger from '~/src/Lib/Logging'
import { defer, EMPTY_OBJECT } from '~/src/Lib/Utils'

import { REACTOR_PRIORITIES } from '../constants'
import { createAppIsReadySelector } from '../utils'

const logger = createLogger('Store/sse')
const defaultState = {
  connecting: {},
  connections: {},
  lastError: null,
}

let job = null
let received = 0
let handled = 0
let invalid = 0

const CONNECT_STREAM = 'CONNECT_STREAM'
const CONNECTING_STREAM = 'CONNECTING_STREAM'
const DISCONNECT_STREAM = 'DISCONNECT_STREAM'

let queue

const jobHandler = (store, deadline) => {
  const { didTimeout } = deadline
  // If we're running due to a timeout, give ourselves half the time, otherwise
  // give ourselves a 10ms buffer to do housekeeping
  const offset = Math.max(didTimeout ? deadline.timeRemaining() / 2 : 10, 10)
  while (queue.size && ((deadline.timeRemaining() - offset) > 0 || didTimeout)) {
    const work = queue.shift()
    const { [work.action]: handler = PACKET_HANDLERS.DEFAULT } = PACKET_HANDLERS
    if (handler(work, store)) {
      handled += 1
    } else {
      invalid += 1
    }
    if (didTimeout) break
  }
  // If there's still work to do, queue next job
  if (queue.size) {
    job = requestIdleCallback(jobHandler.bind(null, store), { timeout: 1000 })
    return
  }
  // No work left
  // Clear job ID
  job = null
}

const streamMessageHandler = ({ packet, store }) => {
  received += 1

  if (!packet?.action) {
    invalid += 1
    logger.debug('SSE invalid packet shape', packet)
    return
  }
  queue.push(packet)
  if (!job) {
    job = requestIdleCallback(jobHandler.bind(null, store), { timeout: 1000 })
  }
  if (received % 100 === 0) {
    logger.debug(store.selectStreamStats())
  }
}

export default {
  name: 'streams',
  init: store => {
    const { ENVIRONMENT } = store.selectConfig() ?? {}
    const { location } = getGlobal()
    if (ENVIRONMENT !== 'production' && !location.hostname.includes('app.aroya.io')) {
      const channel = new BroadcastChannel('FAKE_SSE')
      const fakeStreamHandler = event => {
        streamMessageHandler({
          packet: event.data,
          store,
          dispatch: store.dispatch
        })
      }
      channel.addEventListener('message', fakeStreamHandler)
      return () => {
        channel.removeEventListener('message', fakeStreamHandler)
        channel.close()
        if (job) {
          cancelIdleCallback(job)
        }
      }
    }
    return () => {
      if (job) cancelIdleCallback(job)
      queue = null
    }
  },
  reducer: (state = defaultState, action = EMPTY_OBJECT) => {
    switch (action.type) {
      case CONNECT_STREAM: {
        const [facilityId, stream] = action.payload
        const { [facilityId]: _, ...nextConnecting } = state.connecting
        return {
          connecting: nextConnecting,
          connections: {
            ...state.connections,
            [facilityId]: stream,
          },
          lastError: null,
        }
      }
      case CONNECTING_STREAM:
        return {
          ...state,
          connecting: {
            ...state.connecting,
            [action.payload]: Date.now(),
          }
        }
      case DISCONNECT_STREAM: {
        const {
          connecting: { [action.payload]: _, ...nextConnecting },
          connections: { [action.payload]: oldStream, ...nextStream },
        } = state
        if (oldStream && oldStream.readyState < 2 && oldStream.close) {
          defer(() => {
            try {
              oldStream.close()
            } finally {
              // do nothing
            }
          }, defer.priorities.lowest)
        }
        const nextState = {
          connecting: nextConnecting,
          connections: nextStream,
        }
        if (action.error) {
          nextState.lastError = action.error
        }
        return nextState
      }
      default:
        return state && 'connecting' in state ? state : { ...state, ...defaultState }
    }
  },
  selectStreamsRoot: state => state.streams,
  selectStreams: createSelector(
    'selectStreamsRoot',
    streams => streams.connections
  ),
  selectStreamStats: () => [
    'SSE Message Handler Status:',
    `received packets: ${received}`,
    `invalid packets:  ${invalid}`,
    `handled packets:  ${handled}`,
    `queued packets: ${queue?.size ?? 0}`,
  ].join('\n'),
  reactConnectStream: createAppIsReadySelector({
    dependencies: ['selectFullMe', 'selectStreamsRoot', 'selectCurrentFacilityId'],
    resultFn: (me, streams, currentFacilityId) => {
      if (config.ENABLE_WEBSOCKET || !config.ENABLE_EVENTSTREAM) {
        return null
      }

      if (currentFacilityId in streams.connections) {
        return null
      }

      if (currentFacilityId in streams.connecting) {
        const { [currentFacilityId]: connectingTS } = streams.connecting
        const connectingMS = Date.now() - connectingTS
        if (connectingMS < seconds(10)) {
          logger.debug(
            'already trying to connect to this membership, waiting until',
            new Date(connectingTS + seconds(10)).toISOString(),
            'to try again'
          )
          return undefined
        }
      }
      // If we've changed facilities, get a new message queue
      queue = new Queue()
      // Connect for new facility
      return {
        actionCreator: 'doConnectStream',
        args: [currentFacilityId],
        priority: REACTOR_PRIORITIES.HIGH,
      }
    }
  }),
  doDisconnectStream: (facility, error) => ({ dispatch }) => {
    dispatch({
      type: DISCONNECT_STREAM,
      payload: facility,
      error,
    })
  },
  doConnectStream: currentFacilityId => async ({ store }) => {
    store.dispatch({
      type: CONNECTING_STREAM,
      payload: currentFacilityId,
    })
    const streams = store.selectStreams()
    Object.entries(streams)
      .filter(([facId, stream]) => Number(facId) !== currentFacilityId || stream.readyState > 1)
      .forEach(([facId, stream]) => {
        if (stream && typeof stream.close === 'function') {
          stream.close(1000, 'Switched facilities')
        }
        store.doDisconnectStream(facId)
      })
    if (!currentFacilityId) {
      logger.warn('doConnectStream unable to connect due to missing or invalid membership:', currentFacilityId)
    }
    const stream = await connect(
      currentFacilityId,
      packet => streamMessageHandler({ packet, store }),
      error => store.doDisconnectStream(currentFacilityId, error)
    )
    if (!stream) return
    store.dispatch({
      type: CONNECT_STREAM,
      payload: [currentFacilityId, stream],
    })
  },
}
