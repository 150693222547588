import { groupBy, path } from 'ramda'
import { createSelector } from 'redux-bundler'
import createAsyncResourceBundle from 'redux-bundler/dist/create-async-resource-bundle'

import ms from 'milliseconds'
import { normalize } from 'normalizr'

import { ENTITIES_RECEIVED } from '~/src/Lib/createEntityBundle'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '~/src/Lib/Utils'
import { REACTOR_PRIORITIES } from '~/src/Store/constants'
import { System } from '~/src/Store/Schemas'
import { palettes } from '~/src/UI/Theme/mui'

const { DEFAULT: colors = palettes.AROYA } = palettes

const systemBundle = createAsyncResourceBundle({
  name: 'system',
  retryAfter: ms.seconds(10),
  persist: true,
  getPromise: ({ dispatch, apiFetch }) => apiFetch('/system/').then(payload => {
    const { entities, result } = normalize(payload, System)
    dispatch({ type: ENTITIES_RECEIVED, payload: entities })
    return { ...payload, results: result }
  }),
})

export default {
  ...systemBundle,
  selectColorsByKey: createSelector(
    'selectItemCategories',
    itemCategories => {
      const defaultColors = Object.values(itemCategories).reduce((colorMap, category) => ({
        ...colorMap,
        [category.key]: category.color,
      }), { WASTE: colors.stone })

      const initial = {
        // DRY phase corresponds to the WET item category
        ...defaultColors,
        DESTROYED: defaultColors.WASTE ?? colors.stone,
        DRY: defaultColors.WET ?? colors.tan,
        FLOWER: defaultColors.FRESH ?? colors.moss,
        HARVESTED: defaultColors.WET ?? colors.tan,
        IPM: colors.ipm.peach,
        PROP: defaultColors.PROP ?? colors.fig,
        TRACKED: defaultColors.FRESH ?? colors.moss,
        VEG: defaultColors.VEG ?? colors.terracotta,
        YOUNG: defaultColors.PROP ?? colors.fig,
      }

      return Object.keys(initial).reduce((result, key) => ({
        ...result,
        [key.toLowerCase()]: initial[key],
      }), initial)
    }
  ),
  selectGroupedUnits: createSelector(
    'selectUnits',
    'selectDataNotificationTypes',
    'selectDataTypes',
    units => {
      const unitGrouper = groupBy(path(['category', 'id']))
      const groupFilter = ([id, categoryUnits]) => id && categoryUnits[0].category.allowPreference
      const unitsByCategory = unitGrouper(Object.values(units))
      return Object.entries(unitsByCategory).filter(groupFilter)
    }
  ),
  selectMetrcItemCategories: createSelector(
    'selectCurrentFacility',
    'selectSystem',
    (facility, system) => system?.metrcItemCategories.filter(mic => mic.state === facility.state) ?? EMPTY_ARRAY,
  ),
  selectUnitsBySymbol: createSelector(
    'selectUnits',
    units => Object.values(units).reduce((bySymbol, unit) => ({ ...bySymbol, [unit.symbol]: unit }), EMPTY_OBJECT)
  ),
  reactSystemFetch: createSelector(
    'selectAuth',
    'selectSystemShouldUpdate',
    ({ authenticated }, shouldUpdate) => {
      if (authenticated && shouldUpdate) {
        return { actionCreator: 'doFetchSystem', priority: REACTOR_PRIORITIES.HIGH }
      }
      return undefined
    },
  )
}
