import {
  identity,
  mergeDeepRight,
  prop,
  reduce,
} from 'ramda'

import { normalize } from 'normalizr'

import createLogger from '~/src/Lib/Logging'
import { uniqueId } from '~/src/Lib/Utils'
import * as schemas from '~/src/Store/Schemas'
import { minutes } from 'milliseconds'

const logger = createLogger('IO/Messages')

const mergeEntities = reduce(mergeDeepRight, {})

export const PACKET_HANDLERS = {
  DEFAULT: packet => logger.warn('unknown packet configuration', { packet, schemas }),
  PERFORM_ACTION: (packets, store, typeKey = 'sse') => {
    const [{ function: functions }] = packets
    if (!Array.isArray(functions)) return
    functions.forEach(f => {
      if (typeof f === 'string' && f in store) {
        store[f]()
        return true
      }

      if (f?.actionCreator && f.actionCreator in store) {
        store.dispatch(f)
        return true
      }

      logger.debug('PERFORM_ACTION with invalid configuration', {
        messageSource: typeKey,
        packet: packets[0],
        actionCreators: Object.keys(store).filter(k => k.match(/^do[A-Z]/))
      })
      return false
    })
  },
  ENTITY_CHANGE: (packet, store, typeKey = 'sse') => {
    const { payload } = packet

    if (Array.isArray(payload) && payload.length) {
      const merged = mergeEntities(payload)
      store.doEntitiesReceived(merged, { [typeKey]: true })
      return true
    }

    logger.debug(`ENTITY_CHANGE with invalid data from ${typeKey}:`, packet)
    return false
  }
}

const invalidSchemas = new Map()

const PACKET_INGESTERS = {
  DEFAULT: identity,
  ENTITY_CHANGE: packet => {
    const { entity, payload, many } = packet
    const { [entity]: entitySchema } = schemas
    if (!entitySchema) {
      const lastInvalid = invalidSchemas.get(entity) ?? 0
      const now = Date.now()
      if (now - lastInvalid > minutes(60)) {
        invalidSchemas.set(entity, now)
        logger.debug('missing schema for entity', entity)
      }
      return null
    }
    return normalize(payload, many ? [entitySchema] : entitySchema).entities
  }
}
const PACKET_ID = {
  PERFORM_ACTION: () => uniqueId('performAction_'),
  ENTITY_CHANGE: prop('entity'),
}

export class Queue extends Map {
  push(item) {
    try {
      const { [item.action]: getId = PACKET_ID.DEFAULT } = PACKET_ID
      const { [item.action]: ingester = PACKET_INGESTERS.DEFAULT } = PACKET_INGESTERS
      const id = getId(item)
      if (!id) {
        logger.debug('missing id for packet', item)
        return
      }
      const ingested = ingester(item)
      if (!ingested) return
      const { action = item.action, payload = [] } = this.get(id) || {}
      this.set(id, { action, payload: payload.concat(ingested) })
    } catch (err) {
      logger.error('SSE packet push error', err)
    }
  }

  shift() {
    if (this.size > 0) {
      const [key, item] = this.entries().next().value
      this.delete(key)
      return item
    }
    return undefined
  }
}
