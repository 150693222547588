import {
  appTimeBundle,
  composeBundlesRaw,
  createCacheBundle,
  createDebugBundle,
  createSelector,
  createUrlBundle,
} from 'redux-bundler'

import detectLocale from 'browser-locale'
import queryString from 'query-string'

import { accountSubscription, activeDeviceList } from '~/src/Account/Management/bundle'
import alerts from '~/src/App/bundle/alerts'
import badges from '~/src/App/bundle/badges'
import appConfig from '~/src/App/config'
import comments from '~/src/Comments/bundle'
import { eventList, events, eventStats } from '~/src/Compliance/bundle'
import {
  cultivarKpiProfile,
  cultivarKpis,
  cultivarList,
  cultivars,
  cultivarStatistic,
  cultivarStatisticList,
  kpiProfiles,
  kpiProfilesList,
  kpiRecipes,
  kpis,
} from '~/src/Cultivars/bundle'
import dataTypes from '~/src/DataType/bundle'
import { targetRanges, targetRangesList } from '~/src/DataType/TargetRange/bundle'
import {
  deviceList,
  deviceNumberOfPlants,
  deviceProvisioning,
  devices,
  foundDevices,
  provisioningStatus,
} from '~/src/Device/bundle'
import dialog from '~/src/Dialog/bundle'
import drawer from '~/src/Drawer/bundle'
import facilities, {
  facilityChart,
  facilityEfficiency,
  facilityInventory,
  facilityIrrigationActiveSchedules,
  facilityIrrigationCompletedEvents,
  facilityIrrigationDeletedSchedules,
  facilityIrrigationEventHistory,
  facilityIrrigationFutureEvents,
  facilityIrrigationLightSchedules,
  facilityIrrigationScheduleHistory,
  facilityIrrigationState,
  facilityPhases,
  floorplans,
  latestDryback,
  latestReadings,
} from '~/src/Facility/bundle'
import flags from '~/src/Flags/bundle'
import drying from '~/src/Flow/bundle/drying'
import { entity as flows, list as flowsList } from '~/src/Flow/bundle/general'
// import flows from '~/src/Flow/bundle'
import harvesting from '~/src/Flow/bundle/harvesting'
import processing from '~/src/Flow/bundle/processing'
import processingInventory from '~/src/Flow/bundle/processing/inventory'
import { HARVESTING_FLOW } from '~/src/Flow/constants'
// Entity bundles
import { bundles as harvestBundles } from '~/src/Harvest/bundle'
import growingHarvests from '~/src/HarvestPhaseChange/bundle'
import { inventoryList } from '~/src/Inventory/bundle'
import getApiFetch from '~/src/IO/Api'
import cache from '~/src/IO/Cache'
import pesticideApplications, { list as pesticideApplicationsList } from '~/src/IPM/bundle'
import pesticides, { list as pesticidesList } from '~/src/IPM/Pesticide/bundle'
import {
  facilityIrrigationSchedules,
  irrigationControllerMeta,
  irrigationControllers,
  irrigationControllersList,
  irrigationProgramHistory,
  irrigationScheduleChangeNotificationList,
  irrigationScheduleChangeNotifications,
  irrigationScheduleExpectedEvents,
  irrigationSchedules,
  irrigationSchedulesList,
  irrigationScheduleTemplates,
  irrigationVolume,
  modbusRegisters,
  roomIrrigationSchedules,
} from '~/src/Irrigation/bundle'
import controlSensorBasedEvents
  from '~/src/Irrigation/bundle/emergencyShot/controlSensorBasedEvents'
import controlSensorBasedEventsList
  from '~/src/Irrigation/bundle/emergencyShot/controlSensorBasedEventsList'
import {
  irrigationManualEventList,
  irrigationManualEvents,
} from '~/src/Irrigation/bundle/manualEvents'
import items, { list as itemsList } from '~/src/Item/bundle'
import { controlJournalList, journalV1 } from '~/src/Journal/bundle'
import { labType, labTypeList } from '~/src/Lab/bundle'
import metrc from '~/src/Metrc/bundle'
import metrcLocations, { list as metrcLocationsList } from '~/src/Metrc/Location/bundle'
import { notes } from '~/src/Note/bundle'
import { harvestBatches, packages } from '~/src/Package/bundle'
import packageAdjustmentReasons, {
  list as packageAdjustmentReasonsList,
} from '~/src/Package/PackageAdjustmentReason/bundle'
import { plantBatches, plants, replacePlantInventory } from '~/src/Plant/bundle'
import readings from '~/src/Readings/ManualReading/bundle'
import { recipeList, recipes } from '~/src/Recipe/bundle'
import {
  dripDrainChart,
  dryback,
  lastHarvest,
  nextHarvest,
  roomChart,
  roomCultivarsList,
  roomHarvestsList,
  roomInventory,
  roomList,
  rooms,
} from '~/src/Room/bundle'
import roomDashboard from '~/src/Room/Dashboard/bundle'
import { lightSchedules, lightSchedulesList } from '~/src/Room/LightSchedule/bundle'
import { substrateSettings } from '~/src/Room/SubstrateSettings/bundle'
import routes from '~/src/Routes/bundle'
import accountCreation, { ACCOUNT_CREATION } from '~/src/Routes/Register/bundle'
import { logger, loggerList } from '~/src/Setup/bundle'
import grades, { list as gradesList } from '~/src/Setup/Grades/bundle'
import { membershipList, memberships, notificationPreferences } from '~/src/Staff/Membership/bundle'
import facilityRoles from '~/src/Staff/Role/bundle'
import runs from '~/src/Strainrun/Analytics/bundle'
import support from '~/src/Support/bundle'
import { tasks } from '~/src/Task/bundle'
import wasteMethods, { list as wasteMethodsList } from '~/src/Waste/WasteMethod/bundle'
import wasteReasons, { list as wasteReasonsList } from '~/src/Waste/WasteReason/bundle'
import wasteReasonPlantBatches, {
  list as wasteReasonPlantBatchesList,
} from '~/src/Waste/WasteReasonPlantBatch/bundle'
import wasteTypes, { list as wasteTypesList } from '~/src/Waste/WasteType/bundle'
import { save } from '~/vendor/redux-localstorage-simple'

import audio from './bundles/audio'
// System bundles
import auth from './bundles/auth'
import barcode from './bundles/barcode'
import chart, { annotations } from './bundles/chart'
import config from './bundles/config'
import dimensions from './bundles/dimensions'
import prepareEntityBundles from './bundles/entities'
import labelsList from './bundles/labels'
import me from './bundles/me'
import currentFacility from './bundles/me/currentFacility'
import mySettings from './bundles/mySettings'
import onlineBundle from './bundles/online'
import reactor from './bundles/reactor'
import scale from './bundles/scale'
import snackbar from './bundles/snackbar'
import sockets from './bundles/socketsV2'
import sse from './bundles/sse'
import system from './bundles/system'
import tabState from './bundles/tabState'
import thirdParty from './bundles/thirdParty'
import userSettingsEntity from './bundles/userSettingsEntity'
import { URL_ACTION } from './constants'

const cacheBundle = createCacheBundle({
  cacheFn: cache.set,
  logger: cache.logger,
})

const extraArgs = {
  name: 'extraArgs',
  getMiddleware: () => save({
    states: [HARVESTING_FLOW, ACCOUNT_CREATION, 'mySettings'],
    namespace: `__${appConfig.APP}__`,
    debounce: 1000,
  }),
  getExtraArgs: store => ({ apiFetch: getApiFetch(store) }),
  doApiFetch: (...args) => ({ apiFetch }) => apiFetch(...args),
  doUpdateQueryAdvanced: (payload, opts = { replace: false }) => ({ store }) => {
    const search = queryString.stringify(payload)
    const oldSearch = store.selectUrlObject().search
    if (search !== oldSearch) {
      store.doUpdateQuery(search, opts)
    }
  },
  selectQueryAdvanced: createSelector(
    'selectUrlObject',
    ({ search }) => queryString.parse(search, { parseNumbers: true, parseBooleans: true })
  ),
}

const locale = {
  name: 'locale',
  reducer: (state = detectLocale()) => state,
  selectLocale: state => state.locale,
}

const isIOPattern = /sav(e|ing)|creat(e|ing)|delet(e|ing)|destroy|updat(e|ing)|fetch/i
const isStartPattern = /start/i
const isReadPattern = /fetch/i
const io = {
  name: 'io',
  reducer: (state = { inflight: { read: 0, write: 0 } }, action = {}) => {
    if (!action.type || !isIOPattern.test(action.type)) return state
    const {
      inflight: { read, write },
    } = state
    const isStart = isStartPattern.test(action.type)
    const isRead = isReadPattern.test(action.type)
    const newState = {
      inflight: {
        read: isRead ? Math.max(0, isStart ? read + 1 : read - 1) : read,
        write: isRead ? write : Math.max(0, isStart ? write + 1 : write - 1),
      },
    }
    return newState
  },
  selectInflight: state => state.io && state.io.inflight,
}

const MAINTENANCE_MODE_ENABLED = 'MAINTENANCE_MODE_ENABLED'
const MAINTENANCE_MODE_DISABLED = 'MAINTENANCE_MODE_DISABLED'

const maintenanceMode = {
  name: 'maintenanceMode',
  reducer: (state = false, action = {}) => {
    switch (action.type) {
      case MAINTENANCE_MODE_ENABLED:
        return true
      case MAINTENANCE_MODE_DISABLED:
        return false
      default:
        return state
    }
  },
  doMaintenanceModeEnable: () => ({ dispatch }) => dispatch({ type: MAINTENANCE_MODE_ENABLED }),
  doMaintenanceModeDisable: () => ({ dispatch }) => dispatch({ type: MAINTENANCE_MODE_DISABLED }),
  selectMaintenanceModeEnabled: state => state.maintenanceMode,
}

const noLogActions = {
  APP_IDLE: 1,
  SET_URL_HISTORY: 1,
  DIMENSIONS_UPDATED: 1,
}

const { 0: harvests, [harvestBundles.length - 1]: phases } = harvestBundles
const entityBundles = [
  controlSensorBasedEvents,
  cultivars,
  cultivarStatistic,
  kpis,
  kpiProfiles,
  kpiRecipes,
  dataTypes,
  devices,
  deviceNumberOfPlants,
  events,
  facilities,
  facilityPhases,
  facilityRoles,
  floorplans,
  flows,
  grades,
  harvestBatches,
  harvests,
  items,
  irrigationSchedules,
  irrigationProgramHistory,
  labType,
  lightSchedules,
  logger,
  memberships,
  metrcLocations,
  notes,
  packages,
  packageAdjustmentReasons,
  phases,
  plantBatches,
  plants,
  replacePlantInventory,
  pesticideApplications,
  pesticides,
  readings,
  recipes,
  rooms,
  substrateSettings,
  targetRanges,
  tasks,
  wasteMethods,
  wasteReasons,
  wasteReasonPlantBatches,
  wasteTypes,
]
const urlBundle = {
  ...createUrlBundle({
    actionType: URL_ACTION,
  }),
  doDownload: url => ({ apiFetch }) => apiFetch(url, null, { download: true })
}
const appReady = {
  name: 'appReady',
  selectAppIsReady: createSelector(
    'selectAuth',
    'selectSwitchingFacilities',
    'selectCurrentFacilityId',
    'selectMe',
    'selectSystem',
    ({ authenticated }, switchingFacilities, currentFacilityId, meData, systemData) => [
      authenticated,
      !switchingFacilities,
      currentFacilityId,
      Array.isArray(meData?.memberships),
      Array.isArray(systemData?.permissions),
    ].every(Boolean)
  ),
}

export default composeBundlesRaw(
  // redux-bundler built-ins
  appTimeBundle,
  onlineBundle,
  createDebugBundle({
    logSelectors: false,
    logState: false,
    actionFilter: action => action && !(action.type in noLogActions)
  }),
  reactor,
  thirdParty,
  urlBundle,
  // System bundles
  accountCreation,
  alerts,
  appReady,
  audio,
  auth,
  barcode,
  config,
  currentFacility,
  dialog,
  drawer,
  flags,
  routes,
  scale,
  sockets,
  sse,
  dimensions,
  cacheBundle,
  extraArgs,
  locale,
  snackbar,
  mySettings,
  io,
  maintenanceMode,
  support,
  tabState,
  // Platform data bundles
  comments,
  accountSubscription,
  activeDeviceList,
  provisioningStatus,
  annotations,
  badges,
  chart,
  controlJournalList,
  deviceProvisioning,
  dryback,
  dripDrainChart,
  eventStats,
  latestDryback,
  foundDevices,
  growingHarvests,
  journalV1,
  latestReadings,
  irrigationControllerMeta,
  irrigationControllers,
  irrigationControllersList,
  modbusRegisters,
  irrigationManualEvents,
  irrigationScheduleChangeNotificationList,
  irrigationScheduleChangeNotifications,
  irrigationScheduleExpectedEvents,
  irrigationScheduleTemplates,
  irrigationSchedulesList,
  lightSchedulesList,
  irrigationVolume,
  targetRangesList,
  metrc,
  // flows,
  harvesting,
  drying,
  processing,
  flows,
  flowsList,
  // harvest bundles
  ...harvestBundles.slice(1, -1),
  // Entity bundles
  me,
  userSettingsEntity,
  system,
  notificationPreferences,
  ...prepareEntityBundles(entityBundles),
  // auto-updating async entity bundles
  lastHarvest,
  nextHarvest,
  // List bundles
  controlSensorBasedEventsList,
  cultivarList,
  deviceList,
  facilityIrrigationSchedules,
  gradesList,
  roomCultivarsList,
  inventoryList,
  irrigationManualEventList,
  itemsList,
  labelsList,
  membershipList,
  metrcLocationsList,
  packageAdjustmentReasonsList,
  pesticideApplicationsList,
  pesticidesList,
  processingInventory,
  recipeList,
  roomChart,
  roomInventory,
  roomIrrigationSchedules,
  roomHarvestsList,
  roomList,
  eventList,
  labTypeList,
  loggerList,
  cultivarStatisticList,
  cultivarKpis,
  cultivarKpiProfile,
  kpiProfilesList,
  runs,
  wasteMethodsList,
  wasteReasonsList,
  wasteReasonPlantBatchesList,
  wasteTypesList,
  // Page bundles
  roomDashboard,
  facilityChart,
  facilityEfficiency,
  facilityInventory,
  facilityIrrigationActiveSchedules,
  facilityIrrigationCompletedEvents,
  facilityIrrigationDeletedSchedules,
  facilityIrrigationEventHistory,
  facilityIrrigationFutureEvents,
  facilityIrrigationLightSchedules,
  facilityIrrigationScheduleHistory,
  facilityIrrigationState,
)
